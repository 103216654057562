<template>

    <div class="h-auto w-full">

        <div v-if="!isOpen" class="w-full p-1" @click="openMargen()" :class="{'h-48': mobile, 'h-full': !mobile}">

            <div class="h-full shadow_box rounded-lg flex flex-col" :class="'bg-box-'+mode">
                
                <div class="h-auto flex flex-row justify-start items-center p-2">
                    <span class="font-medium text-sm" :class="'text-dfont-'+mode">{{ $t('margin') }}</span>
                    <span class="text-2xs ml-auto" :class="'text-font_gray-'+mode">{{ checkDate(lastSale) }}</span>
                </div>

                <div class="h-8 w-full mt-2 flex flex-row justify-center items-center">

                    <img :src="'/img/'+getIcon+'.svg'" class="h-8">

                </div>

                <div class="flex flex-1 min-h-0 flex-col justify-center items-center leading-none">

                    <span class="text-xs opacity-50 mb-1" :class="'text-dfont-'+mode">{{ $t('margin') }}</span>
                    <span class="text-sm font-medium mb-2" :class="getClassColor">{{ data.Month | reduceBigNumbers(2) }} %</span>
                    <span class="text-xs opacity-50 mb-1" :class="'text-dfont-'+mode">{{ $t('value') }} {{ prevPeriod }}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{ data.PastYear | reduceBigNumbers() }} %</span>

                </div>

                <div class="h-8 w-full flex flex-row justify-start items-center px-2">
                    <span class="text-2xs mr-auto" :class="'text-font_gray-'+mode">{{ periodo }}</span>
                    <span class="text-2xs font-medium" :class="'text-dfont-'+mode"><template v-if="data.MonthVariation > 0">+</template>
                        {{ data.MonthVariation | reduceBigNumbers(2) }}
                    </span>
                </div>

            </div>

        </div>

        <div v-if="isOpen" class="h-auto w-full rounded-lg p-2" :class="'bg-box-'+mode">
            <div class="h-10 flex flex-row justify-between items-center" @click="openMargen()">
                <span class="text-lg font-semibold" :class="'text-dfont-'+mode">{{ $t('margin') }}
                    <small class="" :class="'text-font_gray-'+mode">({{ checkDate(lastSale) }})</small>
                </span>
                <i class="mdi mdi-close text-red text-2xl"></i>
            </div>
            <All :model="model" :immediate="true" :config="config" v-slot="{ data: datamargin, loading: loadingmargin }">
                <div v-if="!loadingmargin" class="h-auto">
                    <div class="h-full rounded-lg flex flex-col">
                        <div class="flex-1 flex flex-col justify-center items-center">
                            <div class="h-40 w-40 rounded-full bg-green flex flex-col justify-center items-center leading-tight"
                                 :class="getClassColor">
                                <span class="text-xs text-black opacity-50">{{ $t('margin') }}</span>
                                <span class="text-sm font-bold mb-2" :class="'text-dfont-'+mode">{{ data.Month | reduceBigNumbers() }} %</span>
                                <span class="text-xs text-black opacity-50">{{ $t('value') }} {{ prevPeriod }}</span>
                                <span class="text-sm font-bold mb-2" :class="'text-dfont-'+mode">{{data.PastYear | reduceBigNumbers()}} %</span>
                                <span class="text-xs text-black opacity-50">{{periodo}}</span>
                                <span class="text-sm font-bold " :class="'text-dfont-'+mode"><span v-if="data.MonthVariation >= 0">+</span>{{data.MonthVariation | reduceBigNumbers()}}</span>
                                <!-- <span class="text-xs text-darkgreen mr-2">Margen {{ prevPeriod }}</span>
                                <span class="text-xs font-bold">{{data.PastMonth | reduceBigNumbers()}} %</span> -->
                            </div>
                            <div class="h-40 w-full mt-2 flex flex-col justify-between items-center py-1 mt-2">
                                <div class="h-full w-full flex flex-col ">
                                    <div class="h-auto w-full">
                                        <span class="text-xs text-gray">{{$t('last12Months')}}</span>
                                    </div>
                                    <div class="flex-1 ">
                                        <columnchart :data="datamargin.graph" unit="%"></columnchart>
                                    </div>
                                </div>
                            </div>
                            <div v-if="path != 'zone' && path!= 'representante'" class="h-auto w-full flex flex-row justify-start items-center mt-2">
                                <span class="text-md mt-2" :class="'text-dfont-'+mode">{{$t('national')}}</span>
                            </div>
                            <div v-if="path != 'zone' && path!= 'representante'" class="h-40 w-40 rounded-full bg-green flex flex-col justify-center items-center leading-tight" :class="getClassColor">
                                <span class="text-xs text-black opacity-50">{{$t('margin')}} {{ periodfiltered }}</span>
                                <span class="text-sm font-bold mb-2" :class="'text-dfont-'+mode">{{datamargin['24C8C714394211EA966B005056AEAA71'].Month | reduceBigNumbers()}} %</span>
                                <span class="text-xs text-black opacity-50">{{$t('value')}} {{ prevPeriod }}</span>
                                <span class="text-sm font-bold mb-2" :class="'text-dfont-'+mode">{{datamargin['24C8C714394211EA966B005056AEAA71'].PastYear | reduceBigNumbers()}} %</span>
                                <span class="text-xs text-black opacity-50">{{periodo}}</span>
                                <span class="text-sm font-bold" :class="'text-dfont-'+mode"><span v-if="datamargin['24C8C714394211EA966B005056AEAA71'].MonthVariation >= 0">+</span>{{datamargin['24C8C714394211EA966B005056AEAA71'].MonthVariation | reduceBigNumbers()}}</span>
                                <!-- <span class="text-xs mr-2" :class="'text-dfont-'+mode">{{$t('margin')}} {{ prevPeriod }}</span>
                                <span class="text-xs font-bold">{{datamargin['24C8C714394211EA966B005056AEAA71'].PastMonth | reduceBigNumbers()}} %</span> -->
                            </div>
                            <div v-if="path != 'zone' && path!= 'representante'" class="h-auto w-full flex flex-row justify-start items-center mt-2">
                                <span class="text-md mt-2" :class="'text-dfont-'+mode">{{$t('export')}}</span>
                            </div>
                            <div v-if="path != 'zone' && path!= 'representante'" class="h-40 w-40 rounded-full bg-green flex flex-col justify-center items-center leading-tight mb-4" :class="getClassColor">
                                <span class="text-xs text-black opacity-50">{{$t('margin')}} {{ periodfiltered }}</span>
                                <span class="text-sm font-bold mb-2" :class="'text-dfont-'+mode">{{datamargin['24C85D60394211EA966B005056AEAA71'].Month | reduceBigNumbers()}} %</span>
                                <span class="text-xs text-black opacity-50">{{$t('value')}} {{ prevPeriod }}</span>
                                <span class="text-sm font-bold mb-2" :class="'text-dfont-'+mode">{{datamargin['24C85D60394211EA966B005056AEAA71'].PastYear | reduceBigNumbers()}} %</span>
                                <span class="text-xs text-black opacity-50">{{$t('variation')}}</span>
                                <span class="text-sm font-bold" :class="'text-dfont-'+mode"><span v-if="datamargin['24C85D60394211EA966B005056AEAA71'].MonthVariation >= 0">+</span>{{datamargin['24C85D60394211EA966B005056AEAA71'].MonthVariation | reduceBigNumbers()}}</span>
                                <!-- <span class="text-xs text-darkgreen mr-2">{{$t('margin')}} {{ prevPeriod }}</span>
                                <span class="text-xs font-bold">{{datamargin['24C85D60394211EA966B005056AEAA71'].PastMonth | reduceBigNumbers()}} %</span> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="h-20 relative">
                    <loader :loading="loadingmargin"></loader>
                </div>
            </All>
        </div>
        
    </div>

</template>

<script>

    import { All } from '@/api/components';
    import loader from '../components/loader.vue';
    import { state } from '@/store';
    import columnchart from '@/components/columnchart';

    export default {
        props:['data', 'model', 'config', 'isOpen', 'lastSale'],
        components: {
            All,
            loader,
            columnchart
        },
        methods: {
            openMargen() { this.$emit('openMargen') },
            checkDate(date){

                if(state.period == 'month'){

                    if(this.$moment(new Date(date)).format('M') == this.$moment(new Date(state.month + '/01/' + this.$moment(new Date()).format('YYYY'))).format('M')){

                        return this.$moment(new Date(date)).format('DD MMM')

                    } else if(parseInt(this.$moment(new Date(state.month + '/01/' + this.$moment(new Date()).format('YYYY'))).format('M')) > this.$moment(new Date(date)).format('M')){

                        return this.$moment(new Date(state.month + '/01/' + this.$moment(new Date()).format('YYYY'))).format('MMM')

                    }else{

                        return this.$moment(new Date(state.month + '/01/' + this.$moment(new Date()).format('YYYY'))).endOf('month').format('DD MMM')

                    }

                }

                if(state.period == 'quarter'){

                    if(this.$moment(new Date(date)).format('Q') == this.$moment(new Date()).format('Q')){
                        return this.$moment(new Date(date)).format('DD MMM')
                    } else {
                        return this.$moment(new Date(date)).endOf('quarter').format('DD MMM')
                    }

                }

            }
        },
        computed: {
            mode(){
                return state.mode
            },
            query() {
                return {
                    period: state.period,
                    IndustrialGroup: this.IndustrialGroup
                }
            },
            getClassColor() {
                if (this.data.Month <= this.data.PastYear && this.data.Month  >= this.data.PastYear - this.data.PastYear*.1) {
                    return 'text-med'
                }
                if (this.data.Month <= this.data.PastYear - this.data.PastYear*.1) { return 'text-bad' }
                return 'text-green'
            },
            getIcon(){
                
                if (this.data.Month <= this.data.PastYear && this.data.Month  >= this.data.PastYear - this.data.PastYear*.1) {
                    return 'meh-'+this.mode
                }
                if (this.data.Month <= this.data.PastYear - this.data.PastYear*.1){ 
                    return 'frown-'+this.mode 
                }
                return 'smile-'+this.mode
            },
            periodfiltered() {
                switch (state.period) {
                    case 'month':
                        return this.$moment().format("MMM 'YY")
                        break;

                    case 'quarter':
                        return 'Trim.' + this.$moment().format("Q 'YY")
                        break;

                    case 'year':
                        return this.$moment().format("YYYY")
                        break;
                }
            },
            pmPeriod() {
                switch (state.period) {
                    case 'month':
                        return this.$moment().subtract(1, 'months').format("MMM 'YY")
                        break;

                    case 'quarter':
                        return 'Trim.' + this.$moment().subtract(1, 'quarters').format("Q 'YY")
                        break;

                    case 'year':
                        return this.$moment().subtract(1, 'years').format("YYYY")
                        break;
                }
            },
            prevPeriod() { return this.$moment().subtract(1, 'years').format("YYYY") },
            periodo() {
                switch (state.period) {
                    case 'month':
                        return this.$t("monthlyVariation")
                        break;
                    case 'quarter':
                        return this.$t("quarterlyVariation")
                        break;
                    case 'year':
                        return this.$t("annualVariation")
                        break;
                }
            },
            path() { return this.$route.name },
            mobile(){
                return state.isMobile
            }
        }
    }
</script>
