<template>

    <div class="p-1" :class="{'h-64 flex-none w-full': !oppened && mobile, 'h-auto w-full': oppened, 'h-full flex-none w-full': !oppened && !mobile}" >

        <div v-if="!oppened" class="h-full w-full rounded-lg shadow_box" :class="'bg-box-'+mode" @click="open()">

            <div class="h-full rounded-lg flex flex-col">

                <div class="h-auto flex flex-row justify-start items-center px-2 mt-2">
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{$t('billing')}} </span>
                    <span class="ml-auto text-2xs" :class="'text-font_gray-'+mode">{{ checkDate(lastSale) }}</span>
                </div>

                <div class="h-auto flex flex-col justify-between items-start px-2 mt-1">
                    <div class="h-auto w-full flex flex-row justify-between items-center mb-1">
                        <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('day')}} </span>
                        <span class="text-xs font-medium" :class="'text-dfont-'+mode">{{data.Yesterday | reduceBigNumbers(2)}}€</span>
                    </div>
                    <div class="h-auto w-full flex flex-row justify-between items-center mb-1">
                        <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('accumulated')}}</span>
                        <span class="text-xs font-medium" :class="'text-dfont-'+mode">{{data.Month | reduceBigNumbers(2)}}€</span>
                    </div>
                    <div class="h-auto w-full flex flex-row justify-between items-center mb-1">
                        <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('deliveryNotes')}}</span>
                        <span class="text-xs font-medium" :class="'text-dfont-'+mode">{{ data.DeliveryNote | reduceBigNumbers() }}€</span>
                    </div>
                </div>

                <div class="h-32 flex flex-col justify-center items-center relative overflow-hidden">
                    <div class="h-20 w-20 rounded-full absolute flex flex-col justify-center items-center leading-tight">
                        <span class="text-xs text-text_scope  text-center">{{$t('scope')}}</span>
                        <span class="text-xs font-medium mb-1" :class="'text-dfont-'+mode">{{ data.MonthPreview | reduceBigNumbers() }} €</span>
                        <span class="text-xs text-real ">Real {{prevPeriod}}</span>
                        <span class="text-xs font-medium" :class="'text-dfont-'+mode">{{ data.PastYear | reduceBigNumbers() }} €</span>
                    </div>
                    <solidgauge :data="gaugeData"></solidgauge>
                </div>

                <div class="h-auto w-full flex flex-row justify-start items-center px-2 mt-1">
                    <span class="text-2xs mr-auto" :class="'text-font_gray-'+mode">{{periodo}}</span>
                    <span class="text-2xs font-medium" :class="'text-dfont-'+mode"><span v-if="data.MonthVariation >= 0">+</span>{{ data.MonthVariation | reduceBigNumbers() }} %</span>
                </div>

            </div>

        </div>

        <div v-else class="h-auto w-full p-2 rounded-lg" :class="'bg-box-'+mode" @click="open()">

            <div class="h-10 flex flex-row justify-between items-center">
                <span class="text-lg font-medium" :class="'text-dfont-'+mode">{{$t('billing')}} <small class="text-xs" :class="'text-font_gray-'+mode">{{ checkDate(lastSale) }}</small></span>
                <i class="mdi mdi-close text-red"></i>
            </div>

            <All :model="model" :immediate="true" :config="config" v-slot="{data:detailFacturacion, loading:detailLoading}">

                <div class="h-auto" v-if="!detailLoading">

                    <div class="h-auto" id="total">

                        <div class="h-auto px-2 flex flex-col justify-between items-start border-l-4 border-purple">

                            <div class="h-auto w-full flex flex-row justify-between items-center mb-1">
                                <span class="font-semibold text-sm text-purple">{{$t('total')}}</span>
                            </div>

                            <div class="h-auto w-full flex flex-row justify-between items-center">
                                <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('day')}} </span>
                                <span class="text-xs font-bold" :class="'text-dfont-'+mode">{{data.Yesterday | reduceBigNumbers()}} €</span>
                            </div>

                            <div class="h-auto w-full flex flex-row justify-between items-center">
                                <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('accumulated')}}</span>
                                <span class="text-xs font-bold" :class="'text-dfont-'+mode">{{data.Month | reduceBigNumbers()}} €</span>
                            </div>

                            <div class="h-auto w-full flex flex-row justify-between items-center">
                                <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('deliveryNotes')}} </span>
                                <span class="text-xs font-bold" :class="'text-dfont-'+mode">{{ data.DeliveryNote | reduceBigNumbers() }} €</span>
                            </div>

                        </div>
                        
                        <div class="h-52 py-2 border-l-4 border-purple_transparent flex flex-row justify-center items-center relative">
                            <div class="h-20 w-30 rounded-full absolute flex flex-col justify-center items-center leading-tight">
                                <span class="text-xs text-text_scope  text-center">{{$t('scope')}}</span>
                                <span class="text-sm font-bold mb-2" :class="'text-dfont-'+mode">{{ data.MonthPreview | reduceBigNumbers() }} €</span>
                                <span class="text-xs text-real  text-center">Real {{prevPeriod}}</span>
                                <span class="text-sm font-bold mb-2" :class="'text-dfont-'+mode">{{ data.PastYear | reduceBigNumbers() }} €</span>
                                <span class="text-xs" :class="'text-font_gray-'+mode">{{periodo}}</span>
                                <span class="text-sm font-bold" :class="'text-dfont-'+mode"><span v-if="data.MonthVariation >= 0">+</span>{{ data.MonthVariation | reduceBigNumbers() }}%</span>
                            </div>
                            <solidgauge :data="{actual:data.PreviewPercent, prev:data.PastYearPercent, dif:(data.PastYearPercent - data.PreviewPercent + data.PreviewPercent)}"></solidgauge>
                        </div>
                        
                        <div class="h-auto flex flex-row mb-2">

                            <div class="h-auto w-2/4 flex flex-row justify-center items-center">
                                <span class="text-2xs" :class="'text-font_gray-'+mode"></span>
                            </div>

                            <div class="h-auto w-1/4 flex flex-row justify-center items-center">
                                <span class="text-2xs" :class="'text-font_gray-'+mode">{{yearantant}}</span>
                            </div>

                            <div class="h-auto w-1/4 flex flex-row justify-center items-center">
                                <span class="text-2xs" :class="'text-font_gray-'+mode">{{yearant}}</span>
                            </div>

                            <div class="h-auto w-1/4 flex flex-row justify-center items-center">
                                <span class="text-2xs" :class="'text-font_gray-'+mode">{{yearact}}</span>
                            </div>

                        </div>

                        <div class="h-auto border-l-4 border-orange flex flex-row">
                            <div class="h-auto w-2/4 flex flex-row justify-start pl-2 items-center">
                                <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('unpaidYear')}}</span>
                            </div>
                            <div class="h-auto w-1/4 flex flex-row justify-center items-center">
                                <span class="text-xs font-semibold text-orange">{{detailFacturacion[0].Unpaid[yearantant]| reduceBigNumbers()}} €</span>
                            </div>
                            <div class="h-auto w-1/4 flex flex-row justify-center items-center">
                                <span class="text-xs font-semibold text-orange">{{detailFacturacion[0].Unpaid[yearant] | reduceBigNumbers()}} €</span>
                            </div>
                            <div class="h-auto w-1/4 flex flex-row justify-center items-center">
                                <span class="text-xs font-semibold text-orange">{{detailFacturacion[0].Unpaid[yearact]| reduceBigNumbers()}} €</span>
                            </div>
                        </div>

                        <div class="h-auto border-l-4 border-orange_transparent flex flex-row">
                            <div class="h-auto w-2/4 flex flex-row justify-start pl-2 items-center">
                                <span class="text-xs" :class="'text-font_gray-'+mode">%/{{$t('billing')}}</span>
                            </div>
                            <div class="h-auto w-1/4 flex flex-row justify-center items-center">
                                <span class="text-xs font-semibold" :class="'text-font_gray-'+mode">{{detailFacturacion[0].UnpaidSales[yearantant] | reduceBigNumbers(2)}}%</span>
                            </div>
                            <div class="h-auto w-1/4 flex flex-row justify-center items-center">
                                <span class="text-xs font-semibold" :class="'text-font_gray-'+mode">{{detailFacturacion[0].UnpaidSales[yearant] | reduceBigNumbers(2)}}%</span>
                            </div>
                            <div class="h-auto w-1/4 flex flex-row justify-center items-center">
                                <span class="text-xs font-semibold" :class="'text-font_gray-'+mode">{{detailFacturacion[0].UnpaidSales[yearact] | reduceBigNumbers(2)}}%</span>
                            </div>
                        </div>
                        
                    </div>

                    <div v-if="path != 'zone'" class="h-auto" id="nacional">

                        <div class="h-auto px-2 flex flex-col justify-between items-start border-l-4 border-purple2 mt-4" v-if="flag!=false">

                            <div class="h-auto w-full flex flex-row justify-between items-center mb-1">
                                <span class="font-semibold text-sm text-purple2">{{$t('national')}}</span>
                            </div>

                            <div class="h-auto w-full flex flex-row justify-between items-center">
                                <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('day')}}</span>
                                <span class="text-xs font-bold" :class="'text-dfont-'+mode">{{Object.entries(detailFacturacion)[1][1].Yesterday | reduceBigNumbers()}} €</span>
                            </div>

                            <div class="h-auto w-full flex flex-row justify-between items-center">
                                <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('accumulated')}} {{$t('national')}}</span>
                                <span class="text-xs font-bold" :class="'text-dfont-'+mode">{{Object.entries(detailFacturacion)[1][1].Month | reduceBigNumbers()}} €</span>
                            </div>

                            <div class="h-auto w-full flex flex-row justify-between items-center">
                                <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('deliveryNotes')}}</span>
                                <span class="text-xs font-bold" :class="'text-dfont-'+mode">{{ Object.entries(detailFacturacion)[1][1].DeliveryNote | reduceBigNumbers() }} €</span>
                            </div>

                        </div>

                        <div class="h-52 py-2 border-l-4 border-purple2_transparent flex flex-row justify-center items-center relative" v-if="flag!=false">
                            <div class="h-20 w-30 rounded-full absolute flex flex-col justify-center items-center leading-tight">
                                <span class="text-xs text-text_scope  text-center">{{$t('scope')}}</span>
                                <span class="text-sm font-bold mb-2" :class="'text-dfont-'+mode">{{ Object.entries(detailFacturacion)[1][1].MonthPreview | reduceBigNumbers() }} €</span>
                                <span class="text-xs text-real  text-center">Real {{prevPeriod}}</span>
                                <span class="text-sm font-bold mb-2" :class="'text-dfont-'+mode">{{ Object.entries(detailFacturacion)[1][1].PastYear | reduceBigNumbers() }} €</span>
                                <span class="text-xs"  :class="'text-font_gray-'+mode">{{periodo}}</span>
                                <span class="text-sm font-bold" :class="'text-dfont-'+mode"><span v-if="Object.entries(detailFacturacion)[1][1].MonthVariation >= 0">+</span>{{ Object.entries(detailFacturacion)[1][1].MonthVariation | reduceBigNumbers() }}%</span>
                            </div>
                            <solidgauge :data="{actual:Object.entries(detailFacturacion)[1][1].PreviewPercent, prev:Object.entries(detailFacturacion)[1][1].PastYearPercent, dif:(Object.entries(detailFacturacion)[1][1].PastYearPercent - Object.entries(detailFacturacion)[1][1].PreviewPercent + Object.entries(detailFacturacion)[1][1].PreviewPercent)}"></solidgauge>
                        </div>

                        <div class="h-auto flex flex-row border-l-4 border-purple2_transparent mb-2" v-if="flag!=false">
                            <div class="h-auto w-2/4 flex flex-row justify-center items-center">
                                <span class="text-2xs" :class="'text-font_gray-'+mode"></span>
                            </div>
                            <div class="h-auto w-1/4 flex flex-row justify-center items-center">
                                <span class="text-2xs" :class="'text-font_gray-'+mode">{{yearantant}}</span>
                            </div>
                            <div class="h-auto w-1/4 flex flex-row justify-center items-center">
                                <span class="text-2xs" :class="'text-font_gray-'+mode">{{yearant}}</span>
                            </div>
                            <div class="h-auto w-1/4 flex flex-row justify-center items-center">
                                <span class="text-2xs" :class="'text-font_gray-'+mode">{{yearact}}</span>
                            </div>
                        </div>

                        <div class="h-auto border-l-4 border-purple2_transparent flex flex-row" v-if="flag!=false">
                            <div class="h-auto w-2/4 flex flex-row justify-start pl-2 items-center">
                                <span class="text-xs " :class="'text-font_gray-'+mode">{{$t('unpaidYear')}}</span>
                            </div>
                            <div class="h-auto w-1/4 flex flex-row justify-center items-center">
                                <span class="text-xs font-semibold text-orange">{{Object.entries(detailFacturacion)[1][1].Unpaid[yearantant] | reduceBigNumbers()}} €</span>
                            </div>
                            <div class="h-auto w-1/4 flex flex-row justify-center items-center">
                                <span class="text-xs font-semibold text-orange">{{Object.entries(detailFacturacion)[1][1].Unpaid[yearant] | reduceBigNumbers()}} €</span>
                            </div>
                            <div class="h-auto w-1/4 flex flex-row justify-center items-center">
                                <span class="text-xs font-semibold text-orange">{{Object.entries(detailFacturacion)[1][1].Unpaid[yearact] | reduceBigNumbers()}} €</span>
                            </div>
                        </div>

                        <div class="h-auto border-l-4 border-purple2_transparent flex flex-row" v-if="flag!=false">
                            <div class="h-auto w-2/4 flex flex-row justify-start pl-2 items-center">
                                <span class="text-xs" :class="'text-font_gray-'+mode">%/{{$t('facturation')}}</span>
                            </div>
                            <div class="h-auto w-1/4 flex flex-row justify-center items-center">
                                <span class="text-xs font-semibold" :class="'text-font_gray-'+mode">{{Object.entries(detailFacturacion)[1][1].UnpaidSales[yearantant] | reduceBigNumbers(2)}}%</span>
                            </div>
                            <div class="h-auto w-1/4 flex flex-row justify-center items-center">
                                <span class="text-xs font-semibold" :class="'text-font_gray-'+mode">{{Object.entries(detailFacturacion)[1][1].UnpaidSales[yearant] | reduceBigNumbers(2)}}%</span>
                            </div>
                            <div class="h-auto w-1/4 flex flex-row justify-center items-center">
                                <span class="text-xs font-semibold" :class="'text-font_gray-'+mode">{{Object.entries(detailFacturacion)[1][1].UnpaidSales[yearact] | reduceBigNumbers(2)}}%</span>
                            </div>
                        </div>
                    </div>

                    <div v-if="path != 'zone'" class="h-auto" id="exportacion">
                        <div class="h-auto flex flex-col justify-between items-start mt-4 border-l-4 px-2 border-sky" v-if="flag!=false">
                            <div class="h-auto w-full flex flex-row justify-between items-center mb-1">
                                <span class="font-semibold text-sm text-sky">{{$t('export')}}</span>
                            </div>
                            <div class="h-auto w-full flex flex-row justify-between items-center">
                                <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('day')}}</span>
                                <span class="text-xs font-bold " :class="'text-dfont-'+mode">{{Object.entries(detailFacturacion)[2][1].Yesterday | reduceBigNumbers()}} €</span>
                            </div>
                            <div class="h-auto w-full flex flex-row justify-between items-center">
                                <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('accumulated')}} {{$t('export')}} </span>
                                <span class="text-xs font-bold " :class="'text-dfont-'+mode">{{Object.entries(detailFacturacion)[2][1].Month | reduceBigNumbers()}} €</span>
                            </div>
                            <div class="h-auto w-full flex flex-row justify-between items-center">
                                <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('deliveryNotes')}}</span>
                                <span class="text-xs font-bold " :class="'text-dfont-'+mode">{{ Object.entries(detailFacturacion)[2][1].DeliveryNote | reduceBigNumbers() }} €</span>
                            </div>
                        </div>

                        <div class="h-52 py-2 border-l-4 border-sky_transparent flex flex-row justify-center items-center relative" v-if="flag!=false">

                            <div class="h-20 w-30 rounded-full absolute flex flex-col justify-center items-center leading-tight">
                                <span class="text-xs text-text_scope  text-center">{{$t('scope')}}</span>
                                <span class="text-sm font-bold mb-2" :class="'text-dfont-'+mode">{{ Object.entries(detailFacturacion)[2][1].MonthPreview | reduceBigNumbers() }} €</span>
                                <span class="text-xs text-ral  text-center">Real {{prevPeriod}}</span>
                                <span class="text-sm font-bold mb-2" :class="'text-dfont-'+mode">{{ Object.entries(detailFacturacion)[2][1].PastYear | reduceBigNumbers() }} €</span>
                                <span class="text-xs" :class="'text-font_gray-'+mode">{{periodo}}</span>
                                <span class="text-sm font-bold" :class="'text-dfont-'+mode"><span v-if="Object.entries(detailFacturacion)[2][1].MonthVariation">+</span>{{ Object.entries(detailFacturacion)[2][1].MonthVariation | reduceBigNumbers() }}%</span>
                            </div>
                            <solidgauge :data="{actual:Object.entries(detailFacturacion)[2][1].PreviewPercent, prev:Object.entries(detailFacturacion)[2][1].PastYearPercent, dif:(Object.entries(detailFacturacion)[2][1].PastYearPercent - Object.entries(detailFacturacion)[2][1].PreviewPercent + Object.entries(detailFacturacion)[2][1].PreviewPercent)}"></solidgauge>
                        </div>

                        <div class="h-auto flex flex-row border-l-4 border-sky_transparent mb-2" v-if="flag!=false">
                            <div class="h-auto w-2/4 flex flex-row justify-center items-center">
                                <span class="text-2xs" :class="'text-font_gray-'+mode"></span>
                            </div>
                            <div class="h-auto w-1/4 flex flex-row justify-center items-center">
                                <span class="text-2xs" :class="'text-font_gray-'+mode">{{yearantant}}</span>
                            </div>
                            <div class="h-auto w-1/4 flex flex-row justify-center items-center">
                                <span class="text-2xs" :class="'text-font_gray-'+mode">{{yearant}}</span>
                            </div>
                            <div class="h-auto w-1/4 flex flex-row justify-center items-center">
                                <span class="text-2xs" :class="'text-font_gray-'+mode">{{yearact}}</span>
                            </div>
                        </div>

                        <div class="h-auto border-l-4 border-sky_transparent flex flex-row" v-if="flag!=false">
                            <div class="h-auto w-2/4 flex flex-row justify-start pl-2 items-center">
                                <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('unpaidYear')}}</span>
                            </div>
                            <div class="h-auto w-1/4 flex flex-row justify-center items-center">
                                <span class="text-xs font-semibold text-orange">{{Object.entries(detailFacturacion)[2][1].Unpaid[yearantant] | reduceBigNumbers()}} €</span>
                            </div>
                            <div class="h-auto w-1/4 flex flex-row justify-center items-center">
                                <span class="text-xs font-semibold text-orange">{{Object.entries(detailFacturacion)[2][1].Unpaid[yearant] | reduceBigNumbers()}} €</span>
                            </div>
                            <div class="h-auto w-1/4 flex flex-row justify-center items-center">
                                <span class="text-xs font-semibold text-orange">{{Object.entries(detailFacturacion)[2][1].Unpaid[yearact] | reduceBigNumbers()}} €</span>
                            </div>
                        </div>

                        <div class="h-auto border-l-4 border-sky_transparent flex flex-row" v-if="flag!=false">
                            <div class="h-auto w-2/4 flex flex-row justify-start pl-2 items-center">
                                <span class="text-xs" :class="'text-font_gray-'+mode">%/{{$t('facturation')}}</span>
                            </div>
                            <div class="h-auto w-1/4 flex flex-row justify-center items-center">
                                <span class="text-xs font-semibold" :class="'text-font_gray-'+mode">{{Object.entries(detailFacturacion)[2][1].UnpaidSales[yearantant] | reduceBigNumbers(2)}}%</span>
                            </div>
                            <div class="h-auto w-1/4 flex flex-row justify-center items-center">
                                <span class="text-xs font-semibold" :class="'text-font_gray-'+mode">{{Object.entries(detailFacturacion)[2][1].UnpaidSales[yearant] | reduceBigNumbers(2)}}%</span>
                            </div>
                            <div class="h-auto w-1/4 flex flex-row justify-center items-center">
                                <span class="text-xs font-semibold" :class="'text-font_gray-'+mode">{{Object.entries(detailFacturacion)[2][1].UnpaidSales[yearact] | reduceBigNumbers(2)}}%</span>
                            </div>
                        </div>
                    </div>

                </div>

                <div v-else class="h-20 relative">
                    <loader :loading="detailLoading"></loader>
                </div>
            </All>

        </div>

    </div>

</template>

<script>
import loader from '../components/loader.vue';
import { All } from '@/api/components';
import solidgauge from '../components/solidgauge.vue';
import { state, actions } from '@/store';

export default {
    props:['data', 'model', 'config', 'lastSale','flag'],
    components:{
        loader,
        All,
        solidgauge
    },
    data() {
        return {
            oppened: false,
            yearact: this.$moment().format('YYYY'),
            yearant: this.$moment().subtract(1, 'years').format('YYYY'),
            yearantant: this.$moment().subtract(2, 'years').format('YYYY'),
        }
    },
    methods:{
        open() {
            this.oppened = !this.oppened;
            this.$emit('openFacturation');
        },
        checkDate(date){

            if(state.period == 'month'){

                if(this.$moment(new Date(date)).format('M') == this.$moment(new Date(state.month + '/01/' + this.$moment(new Date()).format('YYYY'))).format('M')){

                    return this.$moment(new Date(date)).format('DD MMM')

                } else if(parseInt(this.$moment(new Date(state.month + '/01/' + this.$moment(new Date()).format('YYYY'))).format('M')) > this.$moment(new Date(date)).format('M')){

                    return this.$moment(new Date(state.month + '/01/' + this.$moment(new Date()).format('YYYY'))).format('MMM')

                }else{

                    return this.$moment(new Date(state.month + '/01/' + this.$moment(new Date()).format('YYYY'))).endOf('month').format('DD MMM')

                }

            }

            if(state.period == 'quarter'){

                if(this.$moment(new Date(date)).format('Q') == this.$moment(new Date()).format('Q')){
                    return this.$moment(new Date(date)).format('DD MMM')
                } else {
                    return this.$moment(new Date(date)).endOf('quarter').format('DD MMM')
                }

            }

        }
    },
    computed:{
        mode(){
            return state.mode
        },
        periodo() {
            switch (state.period) {
                case 'month':
                    return this.$t("monthlyVariation")
                    break;
                case 'quarter':
                    return this.$t("quarterlyVariation")
                    break;
                case 'year':
                    return this.$t("annualVariation")
                    break;
            }
        },
        gaugeData() {
            return{
                actual:this.data.PreviewPercent,
                prev:this.data.PastYearPercent,
                dif:(this.data.PastYearPercent - this.data.PreviewPercent + this.data.PreviewPercent)
            }
        },
        query(){
            return{
                period: state.period
            }
        },
        periodfiltered() {
            switch (state.period) {
                case 'month':
                    return this.$moment().format("MMM 'YY")
                    break;

                case 'quarter':
                    return 'Trim.' + this.$moment().format("Q 'YY")
                    break;

                case 'year':
                    return this.$moment().format("YYYY")
                    break;
            }
        },
        prevPeriod() {
            return this.$moment().subtract(1, 'years').format("YYYY")
        },
        path() {
            return this.$route.name
        },
        mobile(){
            return state.isMobile
        }
    }
}
</script>
