<template>

    <div class="h-auto w-full">

        <div v-if="!isOpen" class="w-full p-1" @click="open()" :class="{'h-48': mobile, 'h-full': !mobile}">

            <div class="h-full shadow_box rounded-lg flex flex-col" :class="'bg-box-'+mode">
                
                <div class="h-auto flex flex-row justify-start items-center p-2">
                    <span class="font-medium text-sm" :class="'text-dfont-'+mode">{{ $t('averagePrice') }}</span>
                    <span class="text-2xs ml-auto" :class="'text-font_gray-'+mode">{{ checkDate(lastSale) }}</span>
                </div>

                <div class="h-8 w-full mt-2 flex flex-row justify-center items-center">

                    <!-- <i class="mdi text-4xl" :class="getIcon"></i> -->
                    <img :src="'/img/'+getIcon+'.svg'" class="h-8">

                </div>

                <div class="flex flex-1 min-h-0 flex-col justify-center items-center leading-none">

                    <span class="text-xs opacity-50 mb-1" :class="'text-dfont-'+mode">{{ $t('averagePriceSimplified') }}</span>
                    <span class="text-sm font-medium mb-2" :class="getClassColor">{{ data.Month | reduceBigNumbers(2) }} €/m<sup>2</sup></span>
                    <span class="text-xs opacity-50 mb-1" :class="'text-dfont-'+mode">{{ $t('value') }} {{ prevPeriod }}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{ data.PastYear | reduceBigNumbers() }} €/m<sup>2</sup></span>

                </div>

                <div class="h-8 w-full flex flex-row justify-start items-center px-2">
                    <span class="text-2xs mr-auto" :class="'text-font_gray-'+mode">{{ periodo }}</span>
                    <span class="text-2xs font-medium" :class="'text-dfont-'+mode"><template v-if="data.MonthVariation > 0">+</template>
                        {{ data.MonthVariation | reduceBigNumbers(2) }} €/m<sup>2</sup>
                    </span>
                </div>

            </div>

        </div>

        <div v-if="isOpen" class="h-auto w-full rounded-lg p-2" :class="'bg-box-'+mode">

            <div class="h-auto">

                <div class="h-10 flex flex-row justify-between items-center" @click="open()">
                    <span class="text-lg font-medium" :class="'text-dfont-'+mode">{{ $t('averagePrice') }} <small class="text-xs" :class="'text-font_gray-'+mode">{{ checkDate(lastSale) }}</small></span>
                    <i class="mdi mdi-close text-red"></i>
                </div>
                
                <All :model="model" :immediate="true" :config="config" :query="query"
                    v-slot="{data: dataPreciomedio, loading: loadingPreciomedio}">

                    <div v-if="!loadingPreciomedio" class="h-auto">

                        <div class="h-full rounded-lg flex flex-col px-2">

                            <div class="flex-1 flex flex-col justify-center items-center">

                                <div class="h-auto w-full mt-2 flex flex-row justify-center items-center">

                                    <img :src="'/img/'+getIcon+'.svg'" class="h-8">

                                </div>

                                <div class="h-auto w-auto  flex flex-col justify-center items-center leading-none">

                                    <span class="text-xs text-black opacity-50 mb-1">{{ $t('averagePriceSimplified') }}</span>
                                    <span class="text-sm font-medium mb-2" :class="getClassColor">{{ data.Month | reduceBigNumbers(2) }} €/m<sup>2</sup></span>
                                    <span class="text-xs text-black opacity-50 mb-1">{{ $t('value') }} {{ prevPeriod }}</span>
                                    <span class="text-sm font-medium mb-2" :class="'text-dfont-'+mode">{{ data.PastYear | reduceBigNumbers() }} €/m<sup>2</sup></span>
                                    <span class="text-xs text-black opacity-50 mb-1">{{ periodo }}</span>
                                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{ data.MonthVariation | reduceBigNumbers() }} €/m<sup>2</sup></span>

                                </div>

                               <div class="h-auto w-full flex flex-col py-1 mt-2">
                                   <span class="text-xs" :class="'text-font_gray-'+mode">{{ $t('last12Months') }}</span>
                                   <div class="h-32 w-full mt-1">
                                       <columnchart :data="dataPreciomedio.graph" unit="€" />
                                   </div>
                                </div>

                                <div v-if="path !== 'zone' && path !== 'representante'" class="h-auto w-full flex flex-row justify-start items-center mt-6">
                                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{ $t('national') }}</span>
                                </div>

                                <div v-if="path !== 'zone' && path !== 'representante'" class="h-auto w-full mt-2 flex flex-row justify-center items-center">

                                    <img :src="'/img/'+getIcon+'.svg'" class="h-8">

                                </div>

                                <div v-if="path !== 'zone' && path !== 'representante'" class="h-auto w-auto flex flex-col justify-center items-center leading-none">

                                    <span class="text-xs text-black opacity-50">{{ $t('averagePriceSimplified') }}</span>

                                    <span class="text-sm font-medium mb-2" :class="getClassColor">
                                        {{ dataPreciomedio['24C8C714394211EA966B005056AEAA71'].Month | reduceBigNumbers(2) }} €/m<sup>2</sup>
                                    </span>

                                    <span class="text-xs text-black opacity-50">{{ $t('value') }} {{ prevPeriod }}</span>

                                    <span class="text-sm font-medium mb-2" :class="'text-dfont-'+mode">
                                        {{ dataPreciomedio['24C8C714394211EA966B005056AEAA71'].PastYear | reduceBigNumbers() }} €/m<sup>2</sup>
                                    </span>

                                    <span class="text-xs text-black opacity-50">{{ periodo }}</span>
                                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">
                                        <span v-if="dataPreciomedio['24C8C714394211EA966B005056AEAA71'].MonthVariation >= 0">+</span>
                                        {{ dataPreciomedio['24C8C714394211EA966B005056AEAA71'].MonthVariation | reduceBigNumbers(2 )}} €/m<sup>2</sup>
                                    </span>

                                </div>
                                
                                <div v-if="path !== 'zone' && path !== 'representante'" class="h-auto w-full flex flex-row justify-start items-center mt-6">
                                    <span class="text-sm uppercase font-medium" :class="'text-dfont-'+mode">{{ $t('export') }}</span>
                                </div>

                                <div v-if="path !== 'zone' && path !== 'representante'" class="h-auto w-full mt-2 flex flex-row justify-center items-center">

                                    <img :src="'/img/'+getIcon+'.svg'" class="h-8">

                                </div>

                                <div v-if="path !== 'zone' && path !== 'representante'" class="h-auto w-auto mb-4 flex flex-col justify-center items-center leading-none">
                                    <span class="text-xs text-black opacity-50">{{ $t('averagePriceSimplified') }}</span>
                                    <span class="text-sm font-medium mb-2" :class="getClassColor">
                                        {{ dataPreciomedio['24C85D60394211EA966B005056AEAA71'].Month | reduceBigNumbers(2)}} €/m<sup>2</sup>
                                    </span>
                                    <span class="text-xs text-black opacity-50">{{ $t('value') }} {{ prevPeriod }}</span>
                                    <span class="text-sm font-medium mb-2" :class="'text-dfont-'+mode">
                                        {{ dataPreciomedio['24C85D60394211EA966B005056AEAA71'].PastYear | reduceBigNumbers()}} €/m<sup>2</sup>
                                    </span>
                                    <span class="text-xs text-black opacity-50">{{periodo}}</span>
                                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">
                                        <span v-if="dataPreciomedio['24C85D60394211EA966B005056AEAA71'].MonthVariation >= 0">+</span>
                                            {{ dataPreciomedio['24C85D60394211EA966B005056AEAA71'].MonthVariation | reduceBigNumbers(2) }} €/m<sup>2</sup>
                                    </span>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div v-else class="h-20 relative"><loader :loading="loadingPreciomedio" /></div>

                </All>

            </div>

        </div>

    </div>

</template>

<script>
    import { All } from '@/api/components';
    import loader from '@/components/loader.vue';
    import { state, actions } from '@/store';
    import columnchart from './columnchart.vue';

    export default {
        props:['data', 'model', 'config', 'isOpen', 'lastSale'],
        components: {
            All,
            loader,
            columnchart
        },
        methods: {
            open() { this.$emit('fromhalfprice') },
            checkDate(date){

                if(state.period == 'month'){

                    if(this.$moment(new Date(date)).format('M') == this.$moment(new Date(state.month + '/01/' + this.$moment(new Date()).format('YYYY'))).format('M')){

                        return this.$moment(new Date(date)).format('DD MMM')

                    } else if(parseInt(this.$moment(new Date(state.month + '/01/' + this.$moment(new Date()).format('YYYY'))).format('M')) > this.$moment(new Date(date)).format('M')){

                        return this.$moment(new Date(state.month + '/01/' + this.$moment(new Date()).format('YYYY'))).format('MMM')

                    }else{

                        return this.$moment(new Date(state.month + '/01/' + this.$moment(new Date()).format('YYYY'))).endOf('month').format('DD MMM')

                    }

                }

                if(state.period == 'quarter'){

                    if(this.$moment(new Date(date)).format('Q') == this.$moment(new Date()).format('Q')){
                        return this.$moment(new Date(date)).format('DD MMM')
                    } else {
                        return this.$moment(new Date(date)).endOf('quarter').format('DD MMM')
                    }

                }

            }
        },
        computed: {
            mode(){
                return state.mode
            },
            periodo() {
                switch (state.period) {
                    case 'month':
                        return this.$t("monthlyVariation")
                        break;
                    case 'quarter':
                        return this.$t("quarterlyVariation")
                        break;
                    case 'year':
                        return this.$t("annualVariation")
                        break;
                }
            },
            query() {
                return {
                    period: state.period,
                    IndustrialGroup: state.industrialGroup
                }
            },
            getClassColor() {
                
                if (this.data.Month <= this.data.PastYear && this.data.Month  >= this.data.PastYear - this.data.PastYear*.1) {
                    return 'text-med'
                }
                
                if (this.data.Month <= this.data.PastYear - this.data.PastYear*.1){ 
                    return 'text-bad' 
                }
                
                return 'text-green'
                
            },
            getIcon(){
                
                if (this.data.Month <= this.data.PastYear && this.data.Month  >= this.data.PastYear - this.data.PastYear*.1) {
                    return 'meh-'+this.mode
                }

                if (this.data.Month <= this.data.PastYear - this.data.PastYear*.1){ 
                    return 'frown-'+this.mode 
                }

                return 'smile-'+this.mode
                
            },
            pmPeriod() {
                switch (state.period) {
                    case 'month':
                        return this.$moment().subtract(1, 'months').format("MMM 'YY")
                        break;
                    case 'quarter':
                        return 'Trim.' + this.$moment().subtract(1, 'quarters').format("Q 'YY")
                        break;
                    case 'year':
                        return this.$moment().subtract(1, 'years').format("YYYY")
                        break;
                }
            },
            periodfiltered() {
                switch (state.period) {
                    case 'month':
                        return this.$moment().format("MMM 'YY")
                        break;
                    case 'quarter':
                        return 'Trim.' + this.$moment().format("Q 'YY")
                        break;
                    case 'year':
                        return this.$moment().format("YYYY")
                        break;
                }
            },
            prevPeriod(){ 
                return this.$moment().subtract(1, 'years').format("YYYY") 
            },
            path(){ 
                return this.$route.name
            },
            mobile(){
                return state.isMobile
            }
        }
    }
</script>
