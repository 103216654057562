<template>

    <div class="p-1 overflow-hidden" :class="{'h-64 flex-none w-full': !oppened && mobile, 'h-auto w-full': oppened, 'h-full flex-none w-full': !oppened && !mobile}" >
        
        <div v-if="!oppened" class="h-full w-full rounded-lg flex flex-col shadow_box" :class="'bg-box-'+mode" @click="open()">

            <div class="h-auto flex flex-row justify-start items-center px-2 mt-2">
                <span class="font-medium text-sm" :class="'text-dfont-'+mode">M<sup>2</sup></span>
                <span class="text-2xs ml-auto" :class="'text-font_gray-'+mode">{{ checkDate(lastSale) }}</span>
            </div>

            <div class="h-auto flex flex-col justify-between items-start px-2 mt-1">
                <div class="h-auto w-full flex flex-row justify-between items-center mb-1">
                    <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('day')}} </span>
                    <span class="text-xs font-medium" :class="'text-dfont-'+mode">{{data.Yesterday | reduceBigNumbers()}} m<sup>2</sup></span>
                </div>
                <div class="h-auto w-full flex flex-row justify-between items-center mb-1">
                    <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('accumulated')}}</span>
                    <span class="text-xs font-medium" :class="'text-dfont-'+mode">{{data.Month | reduceBigNumbers()}} m<sup>2</sup></span>
                </div>
                <div class="h-auto w-full flex flex-row justify-between items-center mb-1">
                    <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('deliveryNotes')}}</span>
                    <span class="text-xs font-medium" :class="'text-dfont-'+mode">{{ data.DeliveryNote | reduceBigNumbers() }} m<sup>2</sup></span>
                </div>
            </div>

            <div class="h-32 flex flex-col justify-center items-center relative overflow-hidden">
                <div class="h-20 w-20 rounded-full absolute flex flex-col justify-center items-center leading-tight">
                    <span class="text-xs text-text_scope  text-center">{{$t('scope')}}</span>
                    <span class="text-xs font-medium mb-1" :class="'text-dfont-'+mode">{{ data.MonthPreview | reduceBigNumbers() }} m<sup>2</sup></span>
                    <span class="text-xs text-real ">Real {{prevPeriod}}</span>
                    <span class="text-xs font-medium" :class="'text-dfont-'+mode">{{ data.PastYear | reduceBigNumbers() }} m<sup>2</sup></span>
                </div>
                <solidgauge :data="{actual:data.PreviewPercent, prev:data.PastYearPercent, dif:(data.PastYearPercent - data.PreviewPercent + data.PreviewPercent)}"></solidgauge>
            </div>

            <div class="h-auto w-full flex flex-row justify-start items-center px-2 mt-1">
                <span class="text-2xs mr-auto" :class="'text-font_gray-'+mode">{{periodo}}</span>
                <span class="text-2xs font-medium" :class="'text-dfont-'+mode"><span v-if="data.MonthVariation >= 0">+</span>{{ data.MonthVariation | reduceBigNumbers() }} %</span>
            </div>

        </div>

        <div v-else class="w-full h-auto p-2 rounded-lg" :class="'bg-box-'+mode" @click="open()">
            <div class="h-10 flex flex-row justify-between items-center">
                <span class="text-lg font-medium" :class="'text-dfont-'+mode">M<sup>2</sup> <small class="text-xs" :class="'text-font_gray-'+mode">{{ checkDate(lastSale) }}</small></span>
                <i class="mdi mdi-close text-red"></i>
            </div>
            <All :model="model" :immediate="true" :config="config" :query="query" v-slot="{data:detailMetros, loading:detailLoading}">
                
                <div class="h-auto" v-if="!detailLoading">

                    <div class="h-auto px-2 flex flex-col justify-between items-start border-l-4 border-purple">

                        <div class="h-auto w-full flex flex-row justify-between items-center mb-1">
                            <span class="font-semibold text-sm text-purple">{{$t('total')}}</span>
                        </div>
                        <div class="h-auto w-full flex flex-row justify-between items-center">
                             <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('day')}} </span>
                            <span class="text-xs font-bold" :class="'text-dfont-'+mode">{{data.Yesterday | reduceBigNumbers()}} m<sup>2</sup></span>
                        </div>
                        <div class="h-auto w-full flex flex-row justify-between items-center">
                            <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('accumulated')}}</span>
                            <span class="text-xs font-bold" :class="'text-dfont-'+mode">{{data.Month | reduceBigNumbers()}} m<sup>2</sup></span>
                        </div>
                        <div class="h-auto w-full flex flex-row justify-between items-center">
                            <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('deliveryNotes')}} </span>
                            <span class="text-xs font-bold" :class="'text-dfont-'+mode">{{ data.DeliveryNote | reduceBigNumbers() }} m<sup>2</sup></span>
                        </div>
                    </div>
                    <div class="h-52 py-2 border-l-4 border-purple_transparent flex flex-row justify-center items-center relative">
                        <div class="h-20 w-30 rounded-full absolute flex flex-col justify-center items-center leading-tight">
                            <span class="text-xs text-text_scope  text-center">{{$t('scope')}}</span>
                            <span class="text-sm font-bold mb-2" :class="'text-dfont-'+mode">{{ data.MonthPreview | reduceBigNumbers() }} m<sup>2</sup></span>
                            <span class="text-xs text-real  text-center">Real {{prevPeriod}}</span>
                            <span class="text-sm font-bold mb-2" :class="'text-dfont-'+mode">{{ data.PastYear | reduceBigNumbers() }} m<sup>2</sup></span>
                            <span class="text-xs" :class="'text-font_gray-'+mode">{{periodo}}</span>
                            <span class="text-sm font-bold " :class="'text-dfont-'+mode"><span v-if="data.MonthVariation >= 0">+</span>{{ data.MonthVariation | reduceBigNumbers() }}%</span>
                        </div>
                        <solidgauge :data="{actual:data.PreviewPercent, prev:data.PastYearPercent, dif:(data.PastYearPercent - data.PreviewPercent + data.PreviewPercent)}"></solidgauge>
                    </div>
                    <div v-if="path != 'zone' && path != 'representante'" class="h-auto px-2 flex flex-col justify-between items-start border-l-4 border-purple2 mt-4">
                        <div class="h-auto w-full flex flex-row justify-between items-center mb-1">
                            <span class="font-semibold text-sm text-purple2">{{$t('national')}}</span>
                        </div>
                        <div class="h-auto w-full flex flex-row justify-between items-center">
                            <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('day')}}</span>
                            <span class="text-xs font-bold" :class="'text-dfont-'+mode">{{Object.entries(detailMetros)[0][1].Yesterday | reduceBigNumbers()}} m<sup>2</sup></span>
                        </div>
                        <div class="h-auto w-full flex flex-row justify-between items-center">
                            <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('accumulated')}} {{$t('national')}} </span>
                            <span class="text-xs font-bold" :class="'text-dfont-'+mode">{{Object.entries(detailMetros)[0][1].Month | reduceBigNumbers()}} m<sup>2</sup></span>
                        </div>
                        <div class="h-auto w-full flex flex-row justify-between items-center">
                            <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('deliveryNotes')}}</span>
                            <span class="text-xs font-bold" :class="'text-dfont-'+mode">{{ Object.entries(detailMetros)[0][1].DeliveryNote | reduceBigNumbers() }} m<sup>2</sup></span>
                        </div>
                    </div>
                    <div v-if="path != 'zone' && path != 'representante'" class="h-52 py-2 border-l-4 border-purple2_transparent flex flex-row justify-center items-center relative">
                        <div class="h-20 w-30 rounded-full absolute flex flex-col justify-center items-center leading-tight">
                            <span class="text-xs text-text_scope  text-center">{{$t('scope')}}</span>
                            <span class="text-sm font-bold mb-2" :class="'text-dfont-'+mode">{{ Object.entries(detailMetros)[0][1].MonthPreview | reduceBigNumbers() }} m<sup>2</sup></span>
                            <span class="text-xs text-real  text-center">Real {{prevPeriod}}</span>
                            <span class="text-sm font-bold mb-2" :class="'text-dfont-'+mode">{{ Object.entries(detailMetros)[0][1].PastYear | reduceBigNumbers() }} m<sup>2</sup></span>
                            <span class="text-xs" :class="'text-font_gray-'+mode">{{periodo}}</span>
                            <span class="text-sm font-bold" :class="'text-dfont-'+mode"><span v-if="Object.entries(detailMetros)[0][1].MonthVariation >= 0">+</span>{{ Object.entries(detailMetros)[0][1].MonthVariation | reduceBigNumbers() }}%</span>
                        </div>
                        <solidgauge :data="{actual:Object.entries(detailMetros)[0][1].PreviewPercent, prev:Object.entries(detailMetros)[0][1].PastYearPercent, dif:(Object.entries(detailMetros)[0][1].PastYearPercent - Object.entries(detailMetros)[0][1].PreviewPercent + Object.entries(detailMetros)[0][1].PreviewPercent)}"></solidgauge>
                    </div>
                    <div v-if="path != 'zone' && path != 'representante'" class="h-auto flex flex-col justify-between items-start mt-4 px-2 border-l-4 border-sky">
                        <div class="h-auto w-full flex flex-row justify-between items-center mb-1">
                            <span class="font-semibold text-sm text-sky">{{$t('export')}}</span>
                        </div>
                        <div class="h-auto w-full flex flex-row justify-between items-center">
                            <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('day')}}</span>
                            <span class="text-xs font-bold text-dfont" :class="'text-dfont-'+mode">{{Object.entries(detailMetros)[1][1].Yesterday | reduceBigNumbers()}} m<sup>2</sup></span>
                        </div>
                        <div class="h-auto w-full flex flex-row justify-between items-center">
                            <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('accumulated')}} {{$t('export')}} </span>
                            <span class="text-xs font-bold text-dfont" :class="'text-dfont-'+mode">{{Object.entries(detailMetros)[1][1].Month | reduceBigNumbers()}} m<sup>2</sup></span>
                        </div>
                        <div class="h-auto w-full flex flex-row justify-between items-center">
                            <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('deliveryNotes')}}</span>
                            <span class="text-xs font-bold text-dfont" :class="'text-dfont-'+mode">{{ Object.entries(detailMetros)[1][1].DeliveryNote | reduceBigNumbers() }} m<sup>2</sup></span>
                        </div>
                    </div>
                    <div v-if="path != 'zone' && path != 'representante'" class="h-52 py-2 border-l-4 border-sky_transparent flex flex-row justify-center items-center relative">
                        <div class="h-20 w-30 rounded-full absolute flex flex-col justify-center items-center leading-tight">
                            <span class="text-xs text-text_scope  text-center">{{$t('scope')}} {{periodfiltered}}</span>
                            <span class="text-sm font-bold mb-2" :class="'text-dfont-'+mode">{{ Object.entries(detailMetros)[1][1].MonthPreview | reduceBigNumbers() }} m<sup>2</sup></span>
                            <span class="text-xs text-real  text-center">Real {{prevPeriod}}</span>
                            <span class="text-sm font-bold mb-2" :class="'text-dfont-'+mode">{{ Object.entries(detailMetros)[1][1].PastYear | reduceBigNumbers() }} m<sup>2</sup></span>
                            <span class="text-xs" :class="'text-font_gray-'+mode">{{periodo}}</span>
                            <span class="text-sm font-bold" :class="'text-dfont-'+mode"><span v-if="Object.entries(detailMetros)[1][1].MonthVariation >= 0">+</span>{{ Object.entries(detailMetros)[1][1].MonthVariation | reduceBigNumbers() }}%</span>
                        </div>
                        <solidgauge :data="{actual:Object.entries(detailMetros)[1][1].PreviewPercent, prev:Object.entries(detailMetros)[1][1].PastYearPercent, dif:(Object.entries(detailMetros)[1][1].PastYearPercent - Object.entries(detailMetros)[1][1].PreviewPercent + Object.entries(detailMetros)[1][1].PreviewPercent)}"></solidgauge>
                    </div>
                </div>

                <div v-else class="h-20 relative" :class="'bg-box-'+mode">
                    <loader :loading="detailLoading"></loader>
                </div>

            </All>
            <div class="h-6"></div>
        </div>

    </div>

</template>

<script>
import solidgauge from '../components/solidgauge';
import loader from '../components/loader';
import { All } from '@/api/components';
import { state, actions } from '@/store';

export default {
    props:['data', 'model', 'config', 'lastSale'],
    components: {
        solidgauge,
        loader,
        All
    },
    data(){
        return {
            oppened: false,
            yearact: this.$moment().format('YYYY'),
            yearant: this.$moment().subtract(1, 'years').format('YYYY'),
            yearantant: this.$moment().subtract(2, 'years').format('YYYY'),
        }
    },
    methods: {
        open() {
            this.oppened = !this.oppened,
            this.$emit('openMeters')
        },
        checkDate(date){

            if(state.period == 'month'){

                if(this.$moment(new Date(date)).format('M') == this.$moment(new Date(state.month + '/01/' + this.$moment(new Date()).format('YYYY'))).format('M')){

                    return this.$moment(new Date(date)).format('DD MMM')

                } else if(parseInt(this.$moment(new Date(state.month + '/01/' + this.$moment(new Date()).format('YYYY'))).format('M')) > this.$moment(new Date(date)).format('M')){

                    return this.$moment(new Date(state.month + '/01/' + this.$moment(new Date()).format('YYYY'))).format('MMM')

                }else{

                    return this.$moment(new Date(state.month + '/01/' + this.$moment(new Date()).format('YYYY'))).endOf('month').format('DD MMM')

                }

            }

            if(state.period == 'quarter'){

                if(this.$moment(new Date(date)).format('Q') == this.$moment(new Date()).format('Q')){
                    return this.$moment(new Date(date)).format('DD MMM')
                } else {
                    return this.$moment(new Date(date)).endOf('quarter').format('DD MMM')
                }

            }

        }
    },
    computed:{
        query() {
            return {
                period: state.period,
                IndustrialGroup: state.industrialGroup
            }
        },
        periodo() {
            switch (state.period) {
                case 'month':
                    return this.$t("monthlyVariation")
                    break;
                case 'quarter':
                    return this.$t("quarterlyVariation")
                    break;
                case 'year':
                    return this.$t("annualVariation")
                    break;
            }
        },
        periodfiltered() {
            switch (state.period) {
                case 'month':
                    return this.$moment().format("MMM 'YY")
                    break;
                case 'quarter':
                    return 'trim. ' + this.$moment().format("Q 'YY")
                    break;
                case 'year':
                    return this.$moment().format("YYYY")
                    break;
            }
        },
        prevPeriod() {
            return this.$moment().subtract(1, 'years').format("YYYY")
        },
        path() {
            return this.$route.name
        },
        mode(){
            return state.mode
        },
        mobile(){
            return state.isMobile
        }
    },
    mounted() {
    }
}
</script>
